.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    }

    .modal-header {
        padding: 2px 10px;
        background-color: #1565c0;
        color: white;
        margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
      }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  /* .input-label{
    float: left;
    clear: left;
    text-align: right;
    width: 150px;
  }

  .input-text-field{
  float: left;
  
  } */

  .input-box{
    margin-bottom: 10px;
    margin-left: 20px;
    display: inline-flex;
  }

  .buttons-save-cancel{
    margin-left: 20px;
    /* float: right; */
    display: flex;
    justify-content: flex-end;
    align-content: space-around;
  }

  .multiline-text .input-text-field{
    max-width: 100%;
    width: 550px;
    height: 100px;
  }