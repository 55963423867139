@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');


.signin-btn{
    font-family: 'Anek Malayalam', sans-serif;
    background: #f5b40d;
    border: none;
    outline: none;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    min-width: 200px;
    min-height: 36px;
    cursor: pointer;
    outline: none;
}

.signin-btn:disabled,
.signin-btn[disabled]{
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading{
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    }